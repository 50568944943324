import React from 'react';

import {
    EuiFormRow,
    EuiSwitch,

  } from '@elastic/eui';

// import { useStaticQuery, graphql } from "gatsby";

import { filtersStore } from './../../apollo/client';

const SpreadFilter = () => {

    // брать значение низкого спреда с ID?
 /*    const GET_SPREADS = graphql`
    {
        allContentfulSpread {
            edges {
                node {
                    name
                    id
                }
            }
        }
    }
    `;

    const { allContentfulSpread: { edges: spreads } = {} } = useStaticQuery(GET_SPREADS); */

    const [onlyLowSpread, setOnlyLowSpread] = React.useState(false);

/*     React.useEffect(() => {

        filtersStore({ ...filtersStore(), spreads: (onlyLowSpread) ? ["low"] : ["low", "mid", "high"] });
    }, [onlyLowSpread]) */
 

    const onSwitchChange = () => {

        const store = filtersStore()
        store.spreads.update((onlyLowSpread) ? ["high", "mid", "low"] : ["low"])
        
        setOnlyLowSpread((prev) => !prev);
    }





return (
    <>
    <EuiFormRow label="" display="rowCompressed" hasChildLabel={false}>
        <EuiSwitch
        label="Только с низким спредом"
        name="switch"
        checked={onlyLowSpread}
        onChange={onSwitchChange}
        compressed
        />
    </EuiFormRow>
    </>
)

}      

export default SpreadFilter;