import React from 'react';

import {
    EuiPopover,
    EuiFilterSelectItem,
    EuiFilterGroup,
    EuiFilterButton,
   
  } from '@elastic/eui';
  
import { useStaticQuery, graphql } from "gatsby";

import { filtersStore } from './../../apollo/client';


const BrokerTypeFilter = () => {

    const GET_BROKER_TYPES = graphql`
    {
        allContentfulBrokersType(sort: {fields: name_ru}) {
            edges {
                node {
                    name
                    name_ru
                    contentful_id
                }
            }
        }
    }
    `;

    const { allContentfulBrokersType: { edges: brokerTypes } = {} } = useStaticQuery(GET_BROKER_TYPES);

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [hasActiveFilters, setHasActiveFilters] = React.useState(false);
    const [numActiveFilters, setNumActiveFilters] = React.useState(0);
    const [items, setItems] = React.useState([]);

    React.useEffect(() => {
      if(brokerTypes) {
        setItems(
            brokerTypes.map((brokerType) => {
            return { 
              name: brokerType.node.name,
              name_ru: brokerType.node.name_ru ?? brokerType.node.name,
              contentful_id: brokerType.node.contentful_id,
            }
          })
        );
      }
    }, [brokerTypes]);

    if (!items) return <p>Загрузка...</p>;

    const onButtonClick = () => {
      setIsPopoverOpen(!isPopoverOpen);
    };
  
    const closePopover = () => {
      setIsPopoverOpen(false);
    };
  
    const onFilterSelectItemClick = (clickedItem) => {
  
      let checked = (!clickedItem.checked || typeof clickedItem.checked === undefined) ? "on" : undefined;
  
      const filterItems = [];

      const newVal = items.map((item) => {
          if((item.contentful_id === clickedItem.contentful_id && checked === "on") || (item.checked === "on" && item.contentful_id !== clickedItem.contentful_id)) { filterItems.push(item.contentful_id) }
          return item.contentful_id === clickedItem.contentful_id ? { ...item, checked: checked } : item;
      })

      setItems(newVal); 
      filtersStore().broker_types.update(filterItems);

      //filtersVar({ ...filtersVar(), broker_type: newItems });
  
      const changeNum = clickedItem.checked ? -1 : 1;

      setNumActiveFilters((prev) => prev + changeNum);
      setHasActiveFilters(numActiveFilters + changeNum === 0 ? false : true);
    }
  
  
    const button = (
      <EuiFilterButton
        iconType="arrowDown"
        color="text"
        onClick={onButtonClick}
        isSelected={isPopoverOpen}
        numFilters={items.length}
        hasActiveFilters={hasActiveFilters}
        numActiveFilters={numActiveFilters}>
        Тип брокера
      </EuiFilterButton>
    );

    return (
    <EuiFilterGroup fullWidth>

        <EuiPopover
            id="popoverExampleMultiSelect"
            ownFocus
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="s"
        >
 
            <div className="euiFilterSelect__items">
                {items.map((item) => (

                    <EuiFilterSelectItem checked={item.checked} key={item.id} onClick={() => onFilterSelectItemClick(item)}>

                    {item.name_ru}

                    </EuiFilterSelectItem>
                ))}
            </div>

        </EuiPopover>

      </EuiFilterGroup>
    )
}

export default BrokerTypeFilter;