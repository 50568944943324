
import React from 'react';

import { EuiEmptyPrompt } from '@elastic/eui';

import { Link } from 'gatsby';

export default (props) => {

  let description;

  if(props.for === 'search') {
    description = <>Попробуйте изменить поисковую строку или воспользоваться фильтрами на <Link to="/">Главной странице</Link></>;
  } else {
    description = 'Попробуйте изменить критерии фильтрации или уменьшить количество активных фильров.';
  }

  return <EuiEmptyPrompt
          iconType="editorStrike"
          title={<h2>Нет подходящих брокеров</h2>}
          body={
              <p>
                  {description}
              </p>
          }
        />
};