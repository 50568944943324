import React from 'react';

import {
    EuiFormRow,
    EuiDualRange,

  } from '@elastic/eui';

import { useStaticQuery, graphql } from "gatsby";

import { filtersStore } from './../../apollo/client';


const EstablishedFilter = () => {

    const GET_MIN_AND_MAX_YEAR = graphql`
    query {
        minYear: allContentfulBroker(limit: 1, sort: {fields: established, order: ASC}, filter: {established: {ne: null}}) {
            edges {
                node {
                    established
                }
            }
        }
        
        maxYear:allContentfulBroker(limit: 1, sort: {fields: established, order: DESC}, filter: {established: {ne: null}}) {
            edges {
                node {
                    established
                }
            }
        }
    }
    `;

    const data = useStaticQuery(GET_MIN_AND_MAX_YEAR);

    const [yearRangeVal, setYearRangeVal] = React.useState([1974, new Date().getFullYear()]);
    const [minYear, setMinYear] = React.useState(1974);
    const [maxYear, setMaxYear] = React.useState(new Date().getFullYear());


    React.useEffect(() => {
        if(data) {
            setMinYear(data.minYear.edges[0].node.established)
            setMaxYear(data.maxYear.edges[0].node.established)
            setYearRangeVal([data.minYear.edges[0].node.established, data.maxYear.edges[0].node.established])
        }
    }, [data])


    const onYearRangeChange = (value) => {

        filtersStore().established.update({ min: value[0], max: value[1] });
        setYearRangeVal(value)
    };

    if (!minYear || !maxYear) return <p>Загрузка...</p>;

    return (
        <EuiFormRow fullWidth label="Год основания:" display="rowCompressed">
                <EuiDualRange
                    min={minYear}
                    max={maxYear}
                    fullWidth
                    showInput="inputWithPopover"
                    showTicks
                    ticks={[
                    { label: '1974', value: minYear },
                    { label: '1990', value: 1990 },
                    { label: '2005', value: 2005 },
                    { label: '2021', value: maxYear },
                    ]}
                    value={yearRangeVal}
                    onChange={onYearRangeChange}
                />
            </EuiFormRow>
    ) 
}    

export default EstablishedFilter;