import React from 'react';

import {
    EuiFormRow,
    EuiDualRange,
    EuiFormHelpText
  } from '@elastic/eui';

import { useStaticQuery, graphql } from "gatsby";

import { filtersStore } from './../../apollo/client';

const LeverageFilter = () => {

    const GET_MIN_AND_MAX_LEVERAGE = graphql`
    query 
    {
      minLeverage: allContentfulBroker(limit: 1, sort: {fields: max_leverage, order: ASC}, filter: {max_leverage: {ne: null}}) {
        edges {
            node {
              max_leverage
            }
        }
      }

      maxLeverage: allContentfulBroker(limit: 1, sort: {fields: max_leverage, order: DESC}, filter: {max_leverage: {ne: null}}) {
        edges {
          node {
            max_leverage
          }
        }
      }
    }
    `;

    const data = useStaticQuery(GET_MIN_AND_MAX_LEVERAGE);

    const [leverageRangeVal, setLeverageRangeVal] = React.useState([30, 3000]);
    const [minLeverage, setMinLeverage] = React.useState(30);
    const [maxLeverage, setMaxLeverage] = React.useState(3000);


    React.useEffect(() => {
      if(data) {
        setMinLeverage(data.minLeverage.edges[0].node.max_leverage)
        setMaxLeverage(data.maxLeverage.edges[0].node.max_leverage)
        setLeverageRangeVal([data.minLeverage.edges[0].node.max_leverage, data.maxLeverage.edges[0].node.max_leverage])
      }
    }, [data])

    const onLeverageRangeChange = (value) => {

        filtersStore().max_leverage.update({ min: value[0], max: value[1] });
        setLeverageRangeVal(value);
      };
    

    if (!leverageRangeVal[0]) return <p>Загрузка...</p>;

    const leverageLevels = [
        {
            min: minLeverage,
            max: 500,
            color: 'success',
        },
        {
            min: 501,
            max: maxLeverage,
            color: 'warning',
        },
    ];

  return (
    <>
    <EuiFormRow fullWidth label="Кредитное плечо:" display="rowCompressed">
        <EuiDualRange
            min={minLeverage}
            max={maxLeverage}
            showInput="inputWithPopover"
            levels={leverageLevels}
            showTicks
            prepend="1 : "
            ticks={[
              { label: minLeverage, value: minLeverage },
              { label: '500', value: 500 },
              { label: '1500', value: 1500 },
              { label: maxLeverage, value: maxLeverage },
            ]}
            fullWidth
            value={leverageRangeVal}
            onChange={onLeverageRangeChange}
            aria-describedby="leverageHelp"
        />
    </EuiFormRow>

    <EuiFormHelpText id="leverageHelp">
        Рекомендуемые значения: 1:500 и ниже.
    </EuiFormHelpText>
    </>
  )
}

export default LeverageFilter;