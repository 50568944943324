import React from 'react';

import {
    EuiPopover,
    EuiFilterSelectItem,
    EuiFilterGroup,
    EuiFilterButton,
    EuiFieldSearch,
    EuiPopoverTitle

  } from '@elastic/eui';
  
import { useStaticQuery, graphql } from "gatsby";
import { filtersStore } from './../../apollo/client';

const CountryFilter = () => {

    const GET_COUNTRIES = graphql`
    {
      allContentfulCountry(sort: {order: [ASC], fields: [name_ru]}, filter: {name: {ne: "Unknown"}}) {
        edges {
          node {
            name
            name_ru
            contentful_id
          }
        }
      }
    }
    `;

    const { allContentfulCountry: { edges: countries } = {} } = useStaticQuery(GET_COUNTRIES);

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [hasActiveFilters, setHasActiveFilters] = React.useState(false);
    const [numActiveFilters, setNumActiveFilters] = React.useState(0);

    const [items, setItems] = React.useState([]);

    const [searchItems, setSearchItems] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState('');

    React.useEffect(() => {
      if(countries) {
          
        const initialValue = countries.map((country) => {
            return { 
                name: country.node.name, 
                name_ru: country.node.name_ru, 
                contentful_id: country.node.contentful_id,
            }
        });
        setItems(initialValue);
        setSearchItems(initialValue);
      }
    }, [countries]);

    if (!items) return <p>Загрузка...</p>;

    const onButtonClick = () => {
      setIsPopoverOpen(!isPopoverOpen);
    };
  
    const closePopover = () => {
      setIsPopoverOpen(false);
    };
  
    const onFilterSelectItemClick = (clickedItem) => {
  
      let checked = (!clickedItem.checked || typeof clickedItem.checked === undefined) ? "on" : undefined;
  
      const filterItems = [];

      const newVal = items.map((item) => {
          if((item.contentful_id === clickedItem.contentful_id && checked === "on") || (item.checked === "on" && item.contentful_id !== clickedItem.contentful_id)) { filterItems.push(item.contentful_id) }
          return item.contentful_id === clickedItem.contentful_id ? { ...item, checked: checked } : item;
      })

      setItems(newVal); 
      filtersStore().countries.update(filterItems); 

      setSearchItems(
        searchItems.map((item) => {
         return item.contentful_id === clickedItem.contentful_id ? { ...item, checked: checked } : item;
      })); 
  
      const changeNum = clickedItem.checked ? -1 : 1;
  
      setNumActiveFilters((prev) => prev + changeNum);
      setHasActiveFilters(numActiveFilters + changeNum === 0 ? false : true);
    }
  
  
    const button = (
      <EuiFilterButton
        iconType="arrowDown"
        color="text"
        onClick={onButtonClick}
        isSelected={isPopoverOpen}
        numFilters={searchItems.length}
        hasActiveFilters={hasActiveFilters}
        numActiveFilters={numActiveFilters}>
        Страна регистрации
      </EuiFilterButton>
    );

    const handleSearch = (e) => {

        setSearchValue(e.target.value);

        if(e.target.value.trim() === "") {
            setItems(searchItems);
        }
        setItems(
            searchItems.filter(function (item) {
                return item.name_ru.toLowerCase().includes(e.target.value.trim().toLowerCase());
        })); 
    }

    return (
    <EuiFilterGroup fullWidth>

        <EuiPopover
            id="popoverExampleMultiSelect"
            ownFocus
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="s"
            withTitle
        >
        <EuiPopoverTitle>
          <EuiFieldSearch compressed={true} onChange={handleSearch} value={searchValue} />
        </EuiPopoverTitle>

            <div className="euiFilterSelect__items">
                {items.map((item, index) => (

                    <EuiFilterSelectItem checked={item.checked} key={index} onClick={() => onFilterSelectItemClick(item)}>
      
                    {item.name_ru}

                    </EuiFilterSelectItem>
                ))}
            </div>

        </EuiPopover>

      </EuiFilterGroup>
    )
}

export default CountryFilter;

