import React from 'react';

import {
    EuiListGroup,
    EuiListGroupItem,
  } from '@elastic/eui';

  import { Link } from 'gatsby'

  const SidebarMenu = () => {

    return (
          <EuiListGroup style={{ borderLeft: "1px solid" }}>
            <Link style={{ color: "#343741" }} to={`/`}><EuiListGroupItem style={{ fontSize: 15 }} onClick={() => {}} label="Все брокеры" /></Link>
            <Link style={{ color: "#343741" }} to={`/social-trading-brokers/`}><EuiListGroupItem style={{ fontSize: 15 }} onClick={() => {}} label="Брокеры с соц. торговлей" /></Link>
            <Link style={{ color: "#343741" }} to={`/low-spread-brokers/`}><EuiListGroupItem style={{ fontSize: 15 }} onClick={() => {}} label="Брокеры с низким спредом" /></Link>
            <Link style={{ color: "#343741" }} to={`/ecn-forex-brokers/`}><EuiListGroupItem style={{ fontSize: 15 }} onClick={() => {}} label="ECN-брокеры"/></Link>
            <Link style={{ color: "#343741" }} to={`/crypto-forex-brokers/`}><EuiListGroupItem style={{ fontSize: 15 }} onClick={() => {}} label="Крипто брокеры" /></Link>
             {/* isActive */} 
    
          </EuiListGroup>
      );
}
  export default SidebarMenu;

