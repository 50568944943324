import React from 'react';

import {
    EuiSpacer,
    EuiPanel,
    EuiText,
    EuiShowFor,
    EuiHideFor,
    EuiAccordion,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle
   
  } from '@elastic/eui';

import EstablishedFilter from './established_filter';
import LeverageFilter from './leverage_filter';
import PlatformFilter from './platform_filter';
import FundingMethodFilter from './funding_method_filter';
import RegulatorFilter from './regulator_filter';
import SpreadFilter from './spread_filter';
import InstrumentFilter from './instrument_filter';
import BrokerTypeFilter from './broker_type_filter';
import CountryFilter from './country_filter';
import SidebarMenu from './../sidebar_menu';

const BrokerFilters = () => {

  const extFilters = <>
                      <PlatformFilter />

                      <EuiSpacer size="m" />

                      <InstrumentFilter />

                      <EuiSpacer size="m" />

                      <RegulatorFilter />

                      <EuiSpacer size="m" />

                      <FundingMethodFilter />

                      <EuiSpacer size="m" />

                      <BrokerTypeFilter />

                      <EuiSpacer size="m" />

                      <CountryFilter />
                    </>

    return (
<>

<div className="hide_xs hide_s">
        <EuiPanel>
          <EuiText><h3>Популярные категории:</h3></EuiText>
            <EuiSpacer size="s" />
            <SidebarMenu />
          </EuiPanel>
          <EuiSpacer size="m" />
  </div>
          
        

          
            <EuiPanel className="sticky">
        
          {/* цвет для выделенной кнопки - #85d2d6  */}

            <EuiText><h3>Фильтр брокеров:</h3></EuiText>

              <EuiSpacer size="m" />

            <EstablishedFilter />

            <LeverageFilter />

              <EuiSpacer size="m" />

              <SpreadFilter />  
              
              <EuiSpacer size="m" />

              <div className="hide_m hide_l hide_xl">
                <EuiAccordion
                      id="accordion"
                      buttonContent="Больше фильтров"
                      paddingSize="s">
                  {extFilters}
                  </EuiAccordion>
              </div>

              <div className="hide_xs hide_s">
                {extFilters}
              </div>
        </EuiPanel>
  </>  )
}

export default BrokerFilters;